import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";

import { setCoreComponents } from "./setup/set-components";

setCoreComponents(Vue);

Vue.filter("currency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
