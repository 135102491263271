<template>
  <div class="app-toolbar">
    <v-app-bar app fixed height="68px">
      <v-app-bar-nav-icon @click="toggleNavigation"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="!centro">
        <v-progress-circular
          :size="20"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-toolbar-title>
      <v-toolbar-title v-else> {{ centro }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Notificaciones -->
      <notifications />

      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs" @click="dialog = !dialog">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span> Cerrar Sesión </span>
      </v-tooltip>

      <!-- Modal logout -->
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5 red--text">Cerrar Sesión </v-card-title>
          <v-card-text>
            <strong> ¿Estas seguro de cerrar la sesión? </strong>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text>Cancelar</v-btn>
            <v-btn text color="error" @click="logout">¡Salir!</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </div>
</template>
<script>
import Notifications from "./Notifications.vue";
export default {
  props: ["centro"],
  components: {
    Notifications,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleNavigation() {
      this.$emit("toggleNavigation");
    },
    logout() {
      window.localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>