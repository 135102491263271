<template>
  <div>
    <template v-if="$route.meta.allowAnonymous">
      <router-view />
    </template>
    <template v-else>
      <v-app>
        <div class="app-content">
          <toolbar @toggleNavigation="toggleNavigation" :centro="centro" />
          <navigation ref="nav" @setCenterName="centro = $event" />
          <v-content>
            <breadcrumbs />
            <router-view />
          </v-content>
        </div>
      </v-app>
    </template>
  </div>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    centro: null,
  }),

  methods: {
    toggleNavigation() {
      this.$refs.nav.toggle = !this.$refs.nav.toggle;
    },
  },
};
</script>

