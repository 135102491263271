<template>
  <v-app>
    <snackbar ref="snackbar" />
    <v-row no-gutters>
      <v-col cols="12" md="4" lg="4" class="d-none d-md-block">
        <v-carousel
          cycle
          height="100%"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet height="102vh" tile :style="slide.gradient">
              <v-row class="fill-height" align="center" justify="center">
                <div style="width: 80%; color: white; text-align: center">
                  <h2>
                    {{ slide.title }}
                  </h2>
                  <p>{{ slide.text }}</p>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" md="8" lg="8" class="d-flex" align-self="center">
        <v-col md="10" class="mx-auto">
          <login @setAlert="setAlert($event)" />
        </v-col>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import login from "../components/forms/login.vue";
export default {
  name: "Login",
  components: {
    login,
  },
  data: () => ({
    slides: [
      {
        title: "EFICACIA",
        text: "Nuestra efectividad es indispensable para la buena atención de nuestros clientes",
        gradient:
          "background-color: #0093E9; background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);",
      },
      {
        title: "COMPROMISO",
        text: "Somos comprometidos por el buen servicio",
        gradient:
          "background-color: #FBAB7E; background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);",
      },
      {
        title: "CALIDAD",
        text: "Ofrecemos productos de gran calidad",
        gradient:
          "background-color: #FF3CAC; background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);",
      },
    ],
  }),
  methods: {
    setAlert(data) {
      this.$refs.snackbar.notificaciones.push(data);
    },
  },
};
</script>
