<template>
  <div class="firma">
    <canvas
      class="canvas"
      id="canvas"
      width="400"
      height="250"
      @mousedown="dragStart"
      @mouseup="dragEnd"
      @mouseout="dragEnd"
      @mousemove="draw"
      @touchstart="dragStartTouch"
      @touchmove="drawTouch"
      @touchend="dragEnd"
    ></canvas>
  </div>
</template>
<script>
export default {
  name: "firma",
  data: () => ({
    canvas: null,
    context: null,
    rect: null,
    isDrag: false,
    drawExists: false,
  }),
  mounted() {
    this.canvas = document.querySelector("#canvas");
    this.context = this.canvas.getContext("2d");
    this.context.lineWidth = 2;
    this.context.strokeStyle = "#000000";
    let ctx = document.getElementById("canvas").getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(
      0,
      0,
      document.getElementById("canvas").width,
      document.getElementById("canvas").height
    );
  },
  methods: {
    resizeCanvas() {
      let cardWidth = document.querySelector(".v-dialog").offsetWidth;
      let cardHeight = document.querySelector(".v-dialog").offsetHeight;
      document.getElementById("canvas").width = cardWidth - 50;
      if (cardHeight > 0) {
        document.getElementById("canvas").height = cardHeight / 2;
      } else {
        document.getElementById("canvas").height = 250;
      }
      let ctx = document.getElementById("canvas").getContext("2d");
      ctx.fillStyle = "white";
      ctx.fillRect(
        0,
        0,
        document.getElementById("canvas").width,
        document.getElementById("canvas").height
      );
    },
    cleanCanvas() {
      let ctx = document.getElementById("canvas").getContext("2d");
      ctx.fillStyle = "white";
      ctx.fillRect(
        0,
        0,
        document.getElementById("canvas").width,
        document.getElementById("canvas").height
      );
      this.drawExists = false;
    },
    generateFirma() {
      if (this.drawExists) {
        this.$emit("addFirma", this.canvas.toDataURL());
      }
    },
    draw(e) {
      let rect = this.canvas.getBoundingClientRect();
      let x = e.clientX - rect.left,
        y = e.clientY - rect.top;

      if (!this.isDrag) {
        return;
      }

      this.context.lineTo(x, y);
      this.context.stroke();
      this.drawExists = true;
    },
    dragStart(e) {
      let rect = this.canvas.getBoundingClientRect();
      let x = e.clientX - rect.left,
        y = e.clientY - rect.top;

      this.context.beginPath();
      this.context.lineTo(x, y);
      this.context.stroke();

      this.isDrag = true;
    },
    dragStartTouch(e) {
      let rect = this.canvas.getBoundingClientRect();

      let touch = e.changedTouches[0];

      let x = touch.clientX - rect.left,
        y = touch.clientY - rect.top;

      this.context.beginPath();
      this.context.lineTo(x, y);
      this.context.stroke();
      this.isDrag = true;
    },
    drawTouch(e) {
      let rect = this.canvas.getBoundingClientRect();
      let touch = e.changedTouches[0];

      let x = touch.clientX - rect.left,
        y = touch.clientY - rect.top;

      if (!this.isDrag) {
        return;
      }

      this.context.lineTo(x, y);
      this.context.stroke();
      this.drawExists = true;
    },
    dragEnd(e) {
      this.context.closePath();
      this.isDrag = false;
    },
  },
};
</script>
<style scoped>
.canvas {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>