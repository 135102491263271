import Vue from "vue";
import Vuex from "vuex";

// import axios from "axios";

// axios.defaults.baseURL = "http://localhost:8089";
// axios.defaults.baseURL = "https://www.serve.luxcultury.com";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    messages: [],
  },
  getters: {},
  mutations: {
    setAlert(state, data) {
      state.messages.push(data);
    },
  },
  actions: {
    setAlert(contex, data) {
      contex.commit("setAlert", data);
    },
  },
  modules: {},
});
