import Toolbar from "../components/core/Toolbar.vue";
import Navigation from "../components/core/Navigation.vue";
import Breadcrumbs from "../components/core/Breadcrumbs.vue";
import Firma from "../components/renders/Firma.vue";
import snackbar from "../components/renders/snackbar.vue";

function setCoreComponents(Vue) {
  Vue.component("toolbar", Toolbar);
  Vue.component("navigation", Navigation);
  Vue.component("breadcrumbs", Breadcrumbs);
  Vue.component("Firma", Firma);
  Vue.component("snackbar", snackbar);
}

export { setCoreComponents };
