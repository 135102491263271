import axios from "axios";

// axios.defaults.baseURL = "http://localhost:8089";
axios.defaults.baseURL = "https://apiasor.tecnostylo.com/";

let headers = {
  "Api-Token": "",
};

function post(route, body, app) {
  let user = window.localStorage.getItem("user");

  if (user) {
    user = JSON.parse(user);
    headers["Api-Token"] = user.api_token;
  }
  return new Promise((resolve, reject) => {
    axios.post(route, body, { headers }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error.response.data);
        if (error.response.status === 401) {
          window.localStorage.clear();
          if (app.$route.path != "/login" || app.$route.path != "/login") {
            app.$router.push("/login");
          }
        }
      }
    );
  });
}

function get(route, body, app) {
  let user = window.localStorage.getItem("user");

  if (user) {
    user = JSON.parse(user);
    headers["Api-Token"] = user.api_token;
  }

  return new Promise((resolve, reject) => {
    axios.get(route, { params: body, headers }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error.response.data);
        if (error.response.status === 401) {
          window.localStorage.clear();
          if (app.$route.name != "pdf") {
            if (app.$route.path != "/login" || app.$route.path != "/login") {
              app.$router.push("/login");
            }
          }
        }
      }
    );
  });
}

function get_no_token(route, body, app) {
  return new Promise((resolve, reject) => {
    axios.get(route, { params: body }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error.response.data);
      }
    );
  });
}

export { post, get, get_no_token };
