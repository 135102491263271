import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../views/login.vue";
import Home from "../views/home.vue";

Vue.use(VueRouter);

function sessionValid() {
  return window.localStorage.getItem("user");
}

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      allowAnonymous: true,
    },
    beforeEnter(to, from, next) {
      if (!sessionValid()) next();
      else next("/home");
    },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else {
        let user = JSON.parse(sessionValid());
        if (user.tipo === "administrador") next("/admin/ordenes");
        else next();
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Visitas",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/clientes",
    name: "clientes",
    component: () => import("@/views/clientes.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else next();
    },
    meta: {
      breadcrumb: [
        {
          text: "Clientes",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/facturas",
    name: "facturas",
    component: () => import("../views/facturas/facturas.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else {
        let user = JSON.parse(sessionValid());
        if (user.tipo === "administrador") next("/admin/ordenes");
        else next();
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Lista Ordenes",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/factura/:id",
    name: "factura",
    component: () => import("../views/facturas/factura.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else next();
    },
    meta: {
      breadcrumb: [
        {
          text: "Lista Ordenes",
          href: "/",
        },
        {
          text: "Orden",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/pos/:id",
    name: "pos",
    component: () => import("../views/facturas/pos.vue"),
    meta: {
      allowAnonymous: true,
    },
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else next();
    },
  },
  {
    path: "/pdf/:id/:viewClient/",
    name: "pdf",
    component: () => import("../views/facturas/pdf.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/nueva/:cliente_id/:cita_id",
    name: "nueva",
    component: () => import("../views/facturas/nueva.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else {
        let user = JSON.parse(sessionValid());
        if (user.tipo === "administrador") next("/facturas");
        else next();
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Visitas",
          href: "home",
        },
        {
          text: "Nueva Orden",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/nuevaSinAgenda",
    name: "sinAgendar",
    component: () => import("../views/admin/nueva.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else next();
    },
    meta: {
      breadcrumb: [
        {
          text: "Lista Ordenes",
          href: "facturas",
        },
        {
          text: "Nueva Orden",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/contado",
    name: "contado",
    component: () => import("../views/facturas/contado.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else next();
    },
    meta: {
      breadcrumb: [
        {
          text: "Lista Ordenes",
          href: "facturas",
        },
        {
          text: "Nuevo Contado",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/abonos",
    name: "abonos",
    component: () => import("../views/facturas/abonos.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else next();
    },
    meta: {
      breadcrumb: [
        {
          text: "Lista Abonos",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/abonosPos/:date/:cliente_id",
    name: "abonosPos",
    component: () => import("../views/facturas/posAbonos.vue"),
    meta: {
      allowAnonymous: true,
    },
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else next();
    },
  },
  {
    path: "/adminAbonosPos/:date/:cliente_id/:vendedor",
    name: "adminAbonosPos",
    component: () => import("../views/admin/posAbonos.vue"),
    meta: {
      allowAnonymous: true,
    },
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else next();
    },
  },
  {
    path: "/inventario",
    name: "inventario",
    component: () => import("../views/inventario.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else next();
    },
    meta: {
      breadcrumb: [
        {
          text: "Inventario",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  // Administrador
  {
    path: "/admin/ordenes",
    name: "AdminOrdenes",
    component: () => import("../views/admin/Ordenes.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else {
        let user = JSON.parse(sessionValid());
        if (user.tipo === "administrador") next();
        else next("/login");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Lista Ordenes",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/admin/abonos",
    name: "AdminAbonos",
    component: () => import("../views/admin/abonos.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else {
        let user = JSON.parse(sessionValid());
        if (user.tipo === "administrador") next();
        else next("/login");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Lista Abonos",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/admin/nueva",
    name: "AdminNueva",
    component: () => import("../views/admin/nueva.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else {
        let user = JSON.parse(sessionValid());
        if (user.tipo === "administrador") next();
        else next("/login");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Lista Ordenes",
          href: "AdminOrdenes",
        },
        {
          text: "Nueva Orden",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/admin/muestras",
    name: "AdminMuestras",
    component: () => import("../views/admin/muestras.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else {
        let user = JSON.parse(sessionValid());
        if (user.tipo === "administrador") next();
        else next("/login");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Muestras",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/admin/inventario",
    name: "AdminInventario",
    component: () => import("../views/admin/inventario.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else {
        let user = JSON.parse(sessionValid());
        if (user.tipo === "administrador") next();
        else next("/login");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Inventario",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "/admin/notificaciones",
    name: "AdminMuestras",
    component: () => import("../views/admin/notificaiones.vue"),
    beforeEnter(to, from, next) {
      if (!sessionValid()) next("/login");
      else {
        let user = JSON.parse(sessionValid());
        if (user.tipo === "administrador") next();
        else next("/login");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Notificaciones",
          disabled: true,
          href: "/",
        },
      ],
    },
  },
  {
    path: "*",
    component: Login,
    meta: {
      allowAnonymous: true,
    },
    beforeEnter(to, from, next) {
      if (!sessionValid()) next();
      else next("/home");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
