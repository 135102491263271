<template>
  <div class="notificaciones-alert">
    <v-dialog v-model="dialog" max-width="450">
      <snackbar ref="snackbar" />
      <v-card v-if="notificacion">
        <v-card-title class="text-h5">{{ notificacion.titulo }}</v-card-title>
        <v-card-text>
          <v-form ref="formNotify">
            <v-row dense>
              <v-col cols="12" md="12">
                <v-textarea
                  dense
                  outlined
                  no-resize
                  auto-grow
                  :value="notificacion.notificacion.mensaje"
                  label="Mensaje"
                  readonly
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                md="12"
                v-if="
                  notificacion.notificacion.confirmacion &&
                  !notificacion.notificacion.check_confirmacion &&
                  notificacion.vendedor_id
                "
              >
                <v-btn
                  color="success"
                  :loading="loader"
                  :disabled="loader"
                  @click="setConfimacion(notificacion.id)"
                  >Confirmar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-badge color="error" :value="alerts" :content="alerts" overlap>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card width="300">
        <v-list>
          <v-list-group
            v-for="(item, index) in litaNotificaciones"
            :key="index"
            v-model="item.active"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> {{ item.title }} </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, sub_index) in item.items"
              :key="sub_index"
              @click="openNotify(child)"
            >
              <v-list-item-content> {{ child.titulo }} </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { get, post } from "../../setup/restApi";
import moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    alerts: 0,
    notificaciones: [],
    dialog: false,
    notificacion: null,
    loader: false,
  }),
  computed: {
    litaNotificaciones() {
      let leidas = [],
        noLeidas = [];

      this.notificaciones.forEach((notificacion) => {
        if (!notificacion.notificacion.visto) {
          noLeidas.push(notificacion);
        } else {
          leidas.push(notificacion);
        }
      });

      let lista = [
        {
          title: "No leidas",
          items: noLeidas,
          active: false,
        },
      ];

      if (leidas.length > 0) {
        lista.push({
          title: "Leidas",
          items: leidas,
          active: false,
        });
      }

      if (lista[0].items.length > 0) {
        lista[0].active = true;
      }

      if (lista[0].items.length === 0 && leidas.length > 0) {
        lista[1].active = true;
      }

      this.alerts = noLeidas.length;

      return lista;
    },
  },
  methods: {
    loadNotificaciones() {
      // setInterval(() => {
        get(
          "/admin/loadNotificacionesToolbar",
          { date: moment().format("YYYY-MM-DD") },
          this
        ).then((response) => {
          this.notificaciones = response.data.map((notificacion) => {
            notificacion.notificacion = JSON.parse(notificacion.notificacion);
            return notificacion;
          });
        });
      // }, 1000);
    },
    setVisto(id) {
      post("/admin/setStateVisto", { id, visto: true }, this);
    },
    setConfimacion(id) {
      this.loader = true;
      post("/admin/setConfimacion", { id, check_confirmacion: true }, this)
        .then((response) => {
          this.$refs.snackbar.notificaciones.push({
            message: response.data,
            color: "success",
            timeout: 5000,
          });
        })
        .finally(() => (this.loader = false));
    },
    openNotify(item) {
      this.notificacion = item;
      this.dialog = true;
      if (!item.notificacion.visto) {
        this.setVisto(item.id);
      }
    },
  },
  mounted() {
    this.loadNotificaciones();
  },
};
</script>
