<template>
  <div class="app-navigator">
    <snackbar ref="snackbar" />
    <v-navigation-drawer fixed v-model="toggle" width="260" app>
      <v-list dense nav>
        <v-list-item class="text-center" v-if="!profesional">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-avatar>
            <v-img :src="profesional.foto"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <strong style="white-space: normal">
                {{ profesional.nombre }}
              </strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              <strong>{{ profesional.cedula }}</strong>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense class="py-0">
          <router-link
            :to="route.route"
            v-for="(route, index) in routesRequest"
            :key="index"
          >
            <v-list-item link>
              <v-list-item-icon>
                <v-icon color="primary">{{ route.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ route.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { get } from "../../setup/restApi";
export default {
  data: () => ({
    profesional: null,
    toggle: true,
  }),
  methods: {
    loadPrimaryData() {
      get("/loadData", null, this).then(
        (response) => {
          if (response.success) {
            this.profesional = {
              foto: response.data.foto,
              nombre: response.data.nombre,
              cedula: response.data.cedula,
            };

            // this.centro = response.data.centro;

            this.$emit("setCenterName", response.data.centro);
          }
        },
        (error) => {
          this.$refs.snackbar.notificaciones.push({
            message: error.data,
            color: "error",
            timeout: 5000,
          });
        }
      );
    },
  },
  created() {
    this.loadPrimaryData();
  },
  computed: {
    routesRequest() {
      let vendedores = [
          {
            text: "Visitas",
            icon: "mdi-calendar-account",
            route: "/home",
          },
          {
            text: "Clientes",
            icon: "mdi-account-group",
            route: "/clientes",
          },
          {
            text: "Contado",
            icon: "mdi-cash-multiple",
            route: "/contado",
          },
          {
            text: "Ordenes",
            icon: "mdi-cash-multiple",
            route: "/facturas",
          },
          {
            text: "Abonos",
            icon: "mdi-cash-plus",
            route: "/abonos",
          },
          {
            text: "Inventario",
            icon: "mdi-cart",
            route: "/inventario",
          },
        ],
        admin = [
          {
            text: "Clientes",
            icon: "mdi-account-group",
            route: "/clientes",
          },
          {
            text: "Contado",
            icon: "mdi-cash-multiple",
            route: "/contado",
          },
          {
            text: "Ordenes",
            icon: "mdi-cash-multiple",
            route: "/admin/ordenes",
          },
          {
            text: "Abonos",
            icon: "mdi-cash-plus",
            route: "/admin/abonos",
          },
          {
            text: "Muestras",
            icon: "mdi-cart-check",
            route: "/admin/muestras",
          },
          {
            text: "Inventario",
            icon: "mdi-cart",
            route: "/admin/inventario",
          },
          {
            text: "Notificaciones",
            icon: "mdi-bell",
            route: "/admin/notificaciones",
          },
        ];

      let user = JSON.parse(window.localStorage.getItem("user"));

      if (user.tipo === "administrador") return admin;
      else return vendedores;
    },
  },
};
</script>
