<template>
  <v-alert border="top" colored-border color="primary" elevation="0">
    <v-card elevation="0" @keydown.enter.prevent="login()">
      <v-card-title>Bienvenid@</v-card-title>
      <v-card-subtitle>Ingresa tus credenciales para continuar</v-card-subtitle>
      <v-card-text>
        <v-divider></v-divider>
        <v-form class="mt-4" ref="login">
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                outlined
                dense
                label="Cedula"
                type="number"
                v-model="credentials.cc"
                :rules="rules.cc"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                outlined
                dense
                label="Contraseña"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-lock-open-variant' : 'mdi-lock'"
                @click:append="show = !show"
                v-model="credentials.password"
                :rules="rules.pass"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="login()"
          :loading="loader"
          :disabled="loader"
          >Acceder</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-alert>
</template>
<script>
import { post } from "../../setup/restApi.js";
export default {
  name: "login",
  data: () => ({
    loader: false,
    show: false,
    credentials: {
      cc: "",
      password: "",
    },
    rules: {
      cc: [
        (val) => !!val || "La cedula es requerida",
        (val) =>
          (val && val.length >= 3) ||
          "La cedula debe tener mas de 3 caracteres",
      ],
      pass: [(val) => !!val || "La constraseña es requerida"],
    },
  }),
  methods: {
    login() {
      if (this.$refs.login.validate()) {
        this.loader = true;
        post("/login", this.credentials, this)
          .then(
            (response) => {
              if (response.success) {
                window.localStorage.setItem(
                  "user",
                  JSON.stringify(response.data)
                );

                if (response.data.tipo === "administrador") {
                  this.$router.push("/admin/ordenes");
                } else {
                  this.$router.push("/home");
                }
              }
            },
            (error) => {
              this.$emit("setAlert", {
                message: error.data,
                color: "error",
              });
            }
          )
          .finally(() => (this.loader = false));
      }
    },
  },
};
</script>