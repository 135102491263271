<template>
  <v-snackbars :objects.sync="notificaciones" top right> </v-snackbars>
</template>
<script>
import VSnackbars from "v-snackbars";
export default {
  name: 'snackbar',
  data: () => ({
    notificaciones: [],
  }),
  components: {
    "v-snackbars": VSnackbars,
  },
};
</script>