<template>
  <div class="nav-breadcrumbs" large>
    <v-breadcrumbs :items="items" class="breadcrumbs mx-2" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          class="link"
          :disabled="item.disabled"
          @click="setParams(item)"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <!-- <template slot="item" slot-scope="props">
        <router-link :to="props.item.href" v-if="props.item.href != '/'">{{
          props.item.name
        }}</router-link>
        <span v-else>{{ props.item.name }}</span>
      </template> -->
    </v-breadcrumbs>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [],
  }),
  computed: {
    listItems() {
      let lista = this.items;

      let user = JSON.parse(window.localStorage.getItem("user"));

      if (user.tipo === "administrador") {
        if (this.$route.name === "factura") {
          let index = lista.findIndex(
            (element) => element.href === "/facturas"
          );
          lista.splice(index, 1);
        }
      } else {
        if (this.$route.name === "factura") {
          let index = lista.findIndex(
            (element) => element.href === "/admin/ordenes"
          );
          lista.splice(index, 1);
        }
      }
      return lista;
    },
  },
  watch: {
    $route() {
      this.updateBreadcrumbs();
    },
  },
  methods: {
    updateBreadcrumbs() {
      this.items = this.$route.meta.breadcrumb;
    },
    setParams(item) {
      let router = {
        name: item.href,
      };
      let user = JSON.parse(window.localStorage.getItem("user"));
      if (this.$route.name === "factura") {
        if (user.tipo === "administrador") {
          router.name = "AdminOrdenes";
        } else {
          router.name = "facturas";
        }
      }

      if (this.$route.params.storage != undefined) {
        router = Object.assign(router, {
          params: { storage: this.$route.params.storage },
        });
      }
      this.$router.push(router);
    },
  },
  mounted() {
    this.updateBreadcrumbs();
  },
};
</script>
<style >
.breadcrumbs {
  /* padding-left: 0px !important; */
}
.breadcrumbs .link {
  cursor: pointer;
  color: var(--v-primary-base);
  text-decoration: none;
  /* color: #737373; */
  font: 18px Sans-Serif;
  /* letter-spacing: -1px;
  white-space: nowrap;
  display: inline-block;
  position: relative; */
}
</style>