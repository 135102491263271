<template>
  <div class="home mx-2 mb-4">
    <snackbar ref="snackbar" />
    <v-card>
      <v-card-title>Visitas programadas</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="4">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="date"
                  label="Fecha de visitas"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                first-day-of-week="1"
                locale="es-co"
                v-model="date"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn color="primary" @click="loadVisitas()">
              <v-icon left>mdi-clock</v-icon>
              Actualizar
            </v-btn>
          </v-col>
          <v-col cols="12" md="12">
            <v-data-table
              :loading="loader"
              dense
              :headers="headers"
              :items="lista"
              fixed-header
              height="50vh"
              :footer-props="{
                itemsPerPageText: 'Visitas',
                itemsPerPageOptions: [25, 50, 100, -1],
              }"
              :options="options"
            >
              <template v-slot:item.cliente="{ item }">
                <div class="contacto">
                  <strong>{{ item.cliente.nombre }}</strong>
                  <span>Cc: {{ item.cliente.cedula }}</span>
                </div>
              </template>
              <template v-slot:item.contacto="{ item }">
                <div class="contacto">
                  <span>Tel: {{ item.cliente.telefono }}</span>
                  <span>Dir: {{ item.cliente.direccion }}</span>
                </div>
              </template>
              <template v-slot:item.factura="{ item }">
                <router-link :to="`/nueva/${item.cliente.id}/${item.cita_id}`">
                  <v-btn color="success" small v-if="date === actual">
                    <v-icon left>mdi-currency-usd</v-icon>
                    Nueva Orden
                  </v-btn>
                </router-link>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { get } from "../setup/restApi";

import moment from "moment";
moment.locale("es");
export default {
  name: "home",
  data: () => ({
    loader: false,
    menu: false,
    actual: moment().format("YYYY-MM-DD"),
    date: moment().format("YYYY-MM-DD"),
    headers: [
      {
        align: "start",
        text: "Hora",
        value: "hora",
        sortable: false,
        filterable: false,
      },
      {
        align: "start",
        text: "Cliente",
        value: "cliente",
        sortable: false,
        filterable: false,
      },
      {
        align: "start",
        text: "Contacto",
        value: "contacto",
        sortable: false,
        filterable: false,
      },
      {
        align: "start",
        text: "Servicio",
        value: "servicio",
        sortable: false,
        filterable: false,
      },
      {
        align: "start",
        text: "Notas",
        value: "nota",
        sortable: false,
        filterable: false,
      },
      {
        align: "start",
        text: "",
        value: "factura",
        sortable: false,
        filterable: false,
      },
    ],
    visitas: [],
  }),

  watch: {
    date() {
      this.loadVisitas();
      return;
    },
  },

  computed: {
    lista() {
      return this.visitas.map((visita) => {
        return {
          cita_id: visita.cita_id,
          hora: moment(visita.hora, "HH:mm:ss").format("h:mm A"),
          cliente: {
            id: visita.cliente_id,
            nombre: visita.cliente,
            cedula: visita.cedula,
            telefono: visita.telefono,
            direccion: visita.direccion,
          },
          servicio: visita.servicio,
          nota: visita.nota,
        };
      });
    },
    options() {
      const cantidad = this.lista.length;
      let options = {
        itemsPerPage: 25,
      };

      if (cantidad > 10 && cantidad < 25) {
        options.itemsPerPage = 25;
      } else if (cantidad > 25 && cantidad < 50) {
        options.itemsPerPage = 50;
      } else if (cantidad > 50 && cantidad < 100) {
        options.itemsPerPage = 100;
      } else if (cantidad > 100) {
        options.itemsPerPage = -1;
      }
      return options;
    },
  },

  methods: {
    loadVisitas() {
      this.loader = true;
      get("/visitas", { date: this.date }, this)
        .then(
          (response) => {
            if (response.success) {
              this.visitas = response.data;
            }
          },
          (error) => {
            this.$refs.snackbar.notificaciones.push({
              message: error.data,
              color: "error",
              timeout: 5000,
            });
          }
        )
        .finally(() => (this.loader = false));
    },
  },

  created() {
    this.loadVisitas();
  },
};
</script>
<style scoped>
.contacto {
  display: inline-grid;
  font-size: 13px;
}
</style>
